import { EstablishmentId } from 'api/Establishment/model/EstablishmentId';
import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { CurrencyCode } from 'shared/models/Currency';
import { Timezone } from 'shared/models/DateTime';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { DefaultPour, ILocationSettingsService, IRetailerSettingsResponse } from '../interfaces/ILocationSettingsService';

export class LocationSettingsServiceImpl implements ILocationSettingsService {
    constructor(
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
    ) {}

    // This should probably live in a location service, but the back end is pretty wonky for all of this and should also be cleaned up into a real API at some point
    public getRetailerSettingsInformation(
        retailerId : string,
    ) : Promise<IRetailerSettingsResponse> {
        return AjaxUtils.ajaxGet(url('account:settings:retrieve_retailer_settings_info', null, retailerId, null))
        .then((response : IRetailerSettingsResponse) => Promise.resolve(response))
        .catch((error : Error) => Promise.reject(error));
    }

    public updateRetailerAddressAndContactInformation(
        retailerId : string,
        retailerName : string,
        addressLine1 : string,
        addressLine2 : string,
        contactFirstName : string,
        contactLastName : string,
        contactEmail : string,
        contactPhone : string,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('name', retailerName);
        formData.append('line1', addressLine1);
        formData.append('line2', addressLine2);
        formData.append('first', contactFirstName);
        formData.append('last', contactLastName);
        formData.append('email', contactEmail);
        formData.append('phone', contactPhone);

        return AjaxUtils.ajaxPostForm(url('account:settings:update_retailer', null, retailerId, null), formData)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    public setColleaguesForRetailer(
        retailerId : string,
        colleagueEmails : Array<string>,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('colleagues', JSON.stringify(colleagueEmails));

        return AjaxUtils.ajaxPostForm(url('account:settings:set_colleagues', null, retailerId, null), formData)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    public setTaxNamesForRetailer(
        retailerId : string,
        taxNames : Array<string>,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('tax_names', JSON.stringify(taxNames));

        return AjaxUtils.ajaxPostForm(url('account:settings:set_tax_names', null, retailerId, null), formData)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    public setProductCostSettingsForRetailer(
        retailerId : string,
        includeDiscountsInCost : boolean,
        includeTaxesInCost : boolean,
        includeDepositsInCost : boolean,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('include_deposits', JSON.stringify(includeDepositsInCost));
        formData.append('include_taxes', JSON.stringify(includeTaxesInCost));
        formData.append('include_discounts', JSON.stringify(includeDiscountsInCost));

        return AjaxUtils.ajaxPostForm(url('account:settings:set_product_cost_settings', null, retailerId, null), formData)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    public setUsageLookbackPeriodForRetailer(
        retailerId : string,
        usageLookbackPeriod : number,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('usage_lookback_period_days', JSON.stringify(usageLookbackPeriod));

        return AjaxUtils.ajaxPostForm(url('account:settings:set_usage_lookback_period_days', null, retailerId, null), formData)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    public setEstablishmentIdForRetailer(
        retailerId : string,
        establishmentId : EstablishmentId,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('establishment_id', establishmentId.getValue());

        return AjaxUtils.ajaxPostForm(url('account:settings:set_establishment_id', null, retailerId, null), formData)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    public updateRetailerAccountingStartOfTheWeek(
        retailerId : string,
        startDay : string,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('day_of_week', startDay);

        return AjaxUtils.ajaxPostForm(url('account:settings:update_accounting_start_day_of_week', null, retailerId, null), formData)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    public updateRetailerTimezone(
        retailerId : string,
        timezone : Timezone,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('timezone', Timezone[timezone]);

        return AjaxUtils.ajaxPostForm(url('account:settings:update_retailer_timezone', null, retailerId, null), formData)
        .catch((error : Error) => Promise.reject(error));
    }

    public updateRetailerCurrency(
        retailerId : string,
        currencyCode : CurrencyCode,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('currency', currencyCode);

        return AjaxUtils.ajaxPostForm(url('account:settings:update_retailer_currency', null, retailerId, null), formData)
        .catch((error : Error) => Promise.reject(error));
    }

    public updateRetailerShouldIncludeSKUInOrderCommunications(
        retailerId : string,
        includeSKUInOrderCommunications : boolean,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('should_include_sku', includeSKUInOrderCommunications.toString());

        return AjaxUtils.ajaxPostForm(url('account:settings:update_include_sku_on_order_communications', null, retailerId, null), formData)
        .catch((error : Error) => Promise.reject(error));
    }

    public getRetailerTaxPercent(
        retailerId : string,
    ) : Promise<number | null> {
        return AjaxUtils.ajaxGet(url('account:settings:get_tax_percent', null, retailerId, null))
        .then((response : {tax_percent : number | null}) => Promise.resolve(response.tax_percent))
        .catch((error : Error) => Promise.reject(error));
    }

    public updateRetailerTaxPercent(
        retailerId : string,
        taxPercent : number,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('tax_percent', taxPercent.toString());

        return AjaxUtils.ajaxPostForm(url('account:settings:update_tax_percent', null, retailerId, null), formData)
        .catch((error : Error) => Promise.reject(error));
    }

    public getDefaultPourForRetailer(
        retailerId : string,
    ) : Promise<DefaultPour | null> {
        return AjaxUtils.ajaxGet(url('account:settings:get_default_pour', null, retailerId, null))
        .then((response : { default_pour : {[category : string] : { unit : string, qty : number} } | null }) => {
            const responseDefaultPour = response.default_pour;
            if (responseDefaultPour === null) {
                return Promise.resolve(null);
            }

            const defaultPourByCategory : DefaultPour = {};
            Object.keys(responseDefaultPour).forEach((category) => {
                const defaultPourValue = responseDefaultPour[category];
                let unit : VolumeUnit | MassUnit | 'unit' | 'EA';
                if (defaultPourValue.unit === 'unit' || defaultPourValue.unit === 'EA') {
                    unit = defaultPourValue.unit;
                } else {
                    const productQuantityUnit = this.productJSONToObjectSerializer.getProductQuantityUnit(defaultPourValue.unit);
                    unit = (productQuantityUnit instanceof PackagingId) ? 'unit' : productQuantityUnit;
                }

                const defaultPour = {
                    unit,
                    quantity: defaultPourValue.qty
                };

                defaultPourByCategory[category] = defaultPour;
            });
            return Promise.resolve(defaultPourByCategory);
        })
        .catch((error : Error) => Promise.reject(error));
    }

    public updateDefaultPourForRetailer(
        retailerId : string,
        defaultPour : DefaultPour
    ) : Promise<void> {

        const newDefaultPour : {[category : string] : { unit : string, qty : number} } = {};
        Object.keys(defaultPour).forEach((categoryValue) => {
            const unitAndQuantity = defaultPour[categoryValue];
            newDefaultPour[categoryValue] = {
                unit: unitAndQuantity.unit,
                qty: unitAndQuantity.quantity
            };
        });

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:update_default_pour') + '?retailer_id=' + retailerId, newDefaultPour)
        .catch((error : Error) => Promise.reject(error));
    }

    public setMenuSyncState(
        retailerId : string,
        shouldSync: boolean
    ): Promise<void> {
        const formData : FormData = new FormData();
        formData.append('should_sync_menu', shouldSync ? 'true' : 'false');

        return AjaxUtils.ajaxPostForm(url('account:settings:set_should_sync_menu', null, retailerId, null), formData)
        .catch((error : Error) => Promise.reject(error));
    }

    public setMenuGroupSyncState(
        retailerId : string,
        shouldSync: boolean
    ): Promise<void> {
        const formData : FormData = new FormData();
        formData.append('should_sync_menu_group', shouldSync ? 'true' : 'false');

        return AjaxUtils.ajaxPostForm(url('account:settings:set_should_sync_menu_group', null, retailerId, null), formData)
        .catch((error : Error) => Promise.reject(error));
    }
}
