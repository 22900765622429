import { ITermsConditionsAgreementService } from 'api/Billing/interfaces/ITermsConditionsAgreementService';
import { TermsConditionsAgreement } from 'api/Billing/model/TermsConditionsAgreement';
import { TermsConditionsAgreementId } from 'api/Billing/model/TermsConditionsAgreementId';
import { BillingDataSerializer } from 'api/Billing/serializer/BillingDataSerializer';
import { LocationId } from 'api/Location/model/LocationId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class TermsConditionsAgreementServiceImpl implements ITermsConditionsAgreementService {
    constructor (
        private readonly billingDataSerializer : BillingDataSerializer,
    ) {}

    public getLatestTermsConditionsAgreementForLocation(
        locationId : LocationId
    ) : Promise<TermsConditionsAgreement | null> {
        const queryParameters = {
            retailer_id: locationId.getValue()
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:billing_terms_conditions_agreement'), queryParameters)
        .then((response) => {
            if (Object.keys(response).length === 0) {
                // Retailer has not agreed to terms and conditions yet
                return null;
            }
            return this.billingDataSerializer.getTermsConditionsAgreement(response);
        });
    }

    public createTermsConditionsAgreement(
        locationId: LocationId,
        termsConditionsAgreement: TermsConditionsAgreement
    ) : Promise<TermsConditionsAgreementId> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };
        const termsConditionsAgreementJSON = this.billingDataSerializer.getTermsConditionsAgreementJSON(termsConditionsAgreement);

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:billing_terms_conditions_agreement'), termsConditionsAgreementJSON, queryParameters)
        .then((response) => {
            return response;
        });
    }
}
