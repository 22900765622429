import moment from 'moment-timezone';

import { BankAccount } from 'api/Billing/model/BankAccount';
import { BankAccountId } from 'api/Billing/model/BankAccountId';
import { BevSpotSubscription, SubscriptionStatus } from 'api/Billing/model/BevSpotSubscription';
import { BillingCharge } from 'api/Billing/model/BillingCharge';
import { BillingFrequency } from 'api/Billing/model/BillingFrequency';
import { BillingInvoice } from 'api/Billing/model/BillingInvoice';
import { BillingPlan } from 'api/Billing/model/BillingPlan';
import { BillingPlanId } from 'api/Billing/model/BillingPlanId';
import { BillingPlanTier } from 'api/Billing/model/BillingPlanTier';
import { TermsConditionsAgreement } from 'api/Billing/model/TermsConditionsAgreement';
import { ITermsConditionsAgreementJSONObject } from 'api/Billing/serializer/ITermsConditionsAgreementJSONObject';
import { AccountType } from 'api/Location/model/AccountType';
import { CreditCard } from 'api/Billing/model/CreditCard';
import { CreditCardId } from 'api/Billing/model/CreditCardId';
import { InvoiceId } from 'api/Billing/model/InvoiceId';
import { SubscriptionId } from 'api/Billing/model/SubscriptionId';
import { StringValueMap } from 'api/Core/StringValueMap';
import { ILatestInvoiceData } from 'apps/Billing/reducers/PlansAndBillingReducers';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { LocationId } from 'api/Location/model/LocationId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { DJANGO_API_UTC_TIMESTAMP_FORMAT } from 'shared/constants';

export class BillingDataSerializer {
    public getBillingPlansById(
        billingPlanObjects : Array<object>
    ) : StringValueMap<BillingPlanId, BillingPlan> {

        const billingPlansById = new StringValueMap<BillingPlanId, BillingPlan>();

        for (let i = 0; i < billingPlanObjects.length; i++) {
            const planObject : any = billingPlanObjects[i];

            const planId = new BillingPlanId(planObject.id);
            const plan = this.getBillingPlan(planObject);

            billingPlansById.set(planId, plan);
        }

        return billingPlansById;
    }

    public getBankAccountsAndCreditCardsById(
        billingSourceObjects : Array<any>
    ) : { creditCardsById : StringValueMap<CreditCardId, CreditCard>; bankAccountsById : StringValueMap<BankAccountId, BankAccount> } {
        const creditCardsById = new StringValueMap<CreditCardId, CreditCard>();
        const bankAccountsById = new StringValueMap<BankAccountId, BankAccount>();
        billingSourceObjects.forEach((sourceObject) => {
            if (sourceObject.source_type === 'card') {
                const cardId = new CreditCardId(sourceObject.id);
                const card = this.getCreditCard(sourceObject);
                creditCardsById.set(cardId, card);
            } else {
                const bankAccountId = new BankAccountId(sourceObject.id);
                const bankAccount = this.getBankAccount(sourceObject);
                bankAccountsById.set(bankAccountId, bankAccount);
            }
        });

        return {
            creditCardsById,
            bankAccountsById
        };
    }

    public getCreditCard(
        billingCardObject : any,
    ) : CreditCard {
        return new CreditCard(
            billingCardObject.brand,
            billingCardObject.last4,
            billingCardObject.exp_month - 1, // MonthOfYear is 0-indexed
            billingCardObject.exp_year,
            billingCardObject.card_holder || '',
        );
    }

    public getBankAccount(
        billingBankAccountObject : any
    ) : BankAccount {
        return new BankAccount(
            billingBankAccountObject.account_holder_name,
            billingBankAccountObject.account_holder_type,
            billingBankAccountObject.bank_name,
            billingBankAccountObject.last4,
            billingBankAccountObject.status
        );
    }

    public getSubscriptionData(
        subscriptionObject : any,
    ) : [SubscriptionId, BevSpotSubscription, BillingPlan] {

        // assume only one plan for now
        const billingPlanObject : any = subscriptionObject.items[0].plan;

        const subscriptionData = new BevSpotSubscription(
            new BillingPlanId(billingPlanObject.id),
            moment(subscriptionObject.created_at),
            moment(subscriptionObject.period_start),
            moment(subscriptionObject.period_end),
            subscriptionObject.canceled_at === null ? null : moment(subscriptionObject.canceled_at),
            status === 'canceled' ? SubscriptionStatus.CANCELLED : SubscriptionStatus.ACTIVE, // TODO stripe supports "trialing, active, past_due, canceled, or unpaid" as well as "incomplete and incomplete_expired"
            subscriptionObject.items[0].quantity,
        );

        const subscriptionId = new SubscriptionId(subscriptionObject.id);

        const billingPlan = this.getBillingPlan(billingPlanObject);
        return [subscriptionId, subscriptionData, billingPlan];
    }

    public getInvoiceData(invoiceObject : any) : ILatestInvoiceData {
        const invoiceId = new InvoiceId(invoiceObject.id);

        const amountDueInDollars : number = invoiceObject.amount_due / 100;
        const totalInDollars : number = invoiceObject.total / 100;
        const subtotalInDollars : number = invoiceObject.subtotal / 100;

        const nextPaymentAttemptDate : moment.Moment | null = invoiceObject.next_payment_attempt_date === null ? null : moment.utc(invoiceObject.next_payment_attempt_date);
        const invoice = new BillingInvoice(
            invoiceObject.subscription_id,
            amountDueInDollars,
            totalInDollars,
            subtotalInDollars,
            moment.utc(invoiceObject.date),
            nextPaymentAttemptDate,
            invoiceObject.is_paid,
        );

        const invoiceData : ILatestInvoiceData = {
            invoiceId,
            invoice
        };
        return invoiceData;
    }

    public getBillingCharge = (dict : any) : BillingCharge => {
        if (typeof dict === 'undefined' || dict === null) {
            throw new RuntimeException('unexpected value');
        }

        let invoiceProcessingMetadata : {startDate : moment.Moment, endDate : moment.Moment} | null = null;
        if (dict.metadata && dict.metadata.charge_type === 'invoice_processing') {
            invoiceProcessingMetadata = {
                startDate: moment.unix(dict.metadata.start_date).utc(),
                endDate: moment.unix(dict.metadata.end_date).utc(),
            };
        }

        return new BillingCharge(
            dict.id,
            dict.subscription_id,
            dict.invoice_id,
            dict.amount_in_cents / 100,
            dict.amount_refunded / 100,
            dict.statement_descriptor,
            moment.utc(dict.created_timestamp),
            dict.status,
            dict.paid,
            invoiceProcessingMetadata,
        );
    }

    public parseBillingPlanPriceResponse(response : any) : Map<AccountType, Map<BillingPlanTier, Map<BillingFrequency, number>>> {
        const pricesByAccountTypeAndTierAndFrequency = new Map<AccountType, Map<BillingPlanTier, Map<BillingFrequency, number>>>();

        for (const rawAccountType of Object.keys(response)) {
            const rawPricesByTierAndFrequency : any = response[rawAccountType];
            const pricesByTierAndFrequency = new Map<BillingPlanTier, Map<BillingFrequency, number>>();

            pricesByAccountTypeAndTierAndFrequency.set(
                this.parseAccountType(rawAccountType),
                pricesByTierAndFrequency
            );

            for (const rawTier of Object.keys(rawPricesByTierAndFrequency)) {
                const rawPricesByFrequency : any = rawPricesByTierAndFrequency[rawTier];
                const pricesByFrequency = new Map<BillingFrequency, number>();
                
                pricesByTierAndFrequency.set(
                    this.parseBillingPlanTier(rawTier),
                    pricesByFrequency
                );

                for (const rawFrequency of Object.keys(rawPricesByFrequency)) {
                    pricesByFrequency.set(
                        this.parseBillingFrequency(rawFrequency),
                        Number(rawPricesByFrequency[rawFrequency])
                    );
                }
            }
        }

        return pricesByAccountTypeAndTierAndFrequency;
    }

    public getTermsConditionsAgreement (
        termsConditionsAgreementJSON : ITermsConditionsAgreementJSONObject
    ) : TermsConditionsAgreement {
        const userName = {
            firstName: termsConditionsAgreementJSON.user_name.first_name,
            lastName: termsConditionsAgreementJSON.user_name.last_name,
        };
        return new TermsConditionsAgreement(
            new LocationId(termsConditionsAgreementJSON.retailer_id),
            new UserAccountId(termsConditionsAgreementJSON.user_id),
            moment(termsConditionsAgreementJSON.time_created),
            userName,
        );
    }

    public getTermsConditionsAgreementJSON (
        termsConditionsAgreement : TermsConditionsAgreement
    ) : ITermsConditionsAgreementJSONObject {
        const userName = termsConditionsAgreement.getUserName();
        return {
            retailer_id: termsConditionsAgreement.getLocationId().getValue(),
            user_id: termsConditionsAgreement.getUserId().getValue(),
            time_created: termsConditionsAgreement.getTimeCreated().utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            user_name: {
                first_name: userName.firstName,
                last_name: userName.lastName,
            },
        };
    }

    private getBillingPlan(planObject : any) : BillingPlan {
        const billingFrequency : BillingFrequency = this.calculateBillingFrequency(
            planObject.billing_frequency,
            planObject.interval_count,
        );

        const billingPlanTier : BillingPlanTier | null = (planObject.tier === null) ? null : this.parseBillingPlanTier(planObject.tier);
        const accountType : AccountType | null = (planObject.account_type === null) ? null : this.parseAccountType(planObject.account_type);

        return new BillingPlan(
            planObject.name,
            planObject.price,
            billingFrequency,
            billingPlanTier,
            accountType,
            planObject.active,
        );
    }

    private parseBillingPlanTier(value : string) : BillingPlanTier {
        switch (value) {
            case 'pro': {
                return BillingPlanTier.PRO;
            }
            case 'standard': {
                return BillingPlanTier.STANDARD;
            }
            case 'starter': {
                return BillingPlanTier.STARTER;
            }
            default: {
                throw new RuntimeException('unexpected plan tier: ' + value);
            }
        }
    }

    private parseAccountType(value : string) : AccountType {
        switch (value) {
            case 'food': {
                return AccountType.FOOD;
            }
            case 'beverage': {
                return AccountType.BEVERAGE;
            }
            default: {
                throw new RuntimeException('unexpected account type: ' + value);
            }
        }
    }

    private parseBillingFrequency(value : string) : BillingFrequency {
        switch (value) {
            case 'monthly': {
                return BillingFrequency.MONTHLY;
            }
            case 'quarterly': {
                return BillingFrequency.QUARTERLY;
            }
            case 'semiannually': {
                return BillingFrequency.SEMIANNUALLY;
            }
            case 'annually': {
                return BillingFrequency.ANNUALLY;
            }
            default: {
                throw new RuntimeException('unexpected billing frequency: ' + value);
            }
        }
    }

    private calculateBillingFrequency(billingInterval : string, intervalCount : number) : BillingFrequency {
        switch (billingInterval) {
            case 'month': {
                switch (intervalCount) {
                    case 1: {
                        return BillingFrequency.MONTHLY;
                    }
                    case 3: {
                        return BillingFrequency.QUARTERLY;
                    }
                    case 6: {
                        return BillingFrequency.SEMIANNUALLY;
                    }
                    default: {
                        throw new RuntimeException('unexpected interval count: ' + intervalCount);
                    }
                }
            }
            case 'year': {
                if (1 !== intervalCount) {
                    throw new RuntimeException('unexpected interval count: ' + intervalCount);
                }

                return BillingFrequency.ANNUALLY;
            }
            default: {
                throw new RuntimeException('unexpected billing frequency: ' + billingInterval);
            }
        }
    }
}
