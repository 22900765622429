import { LocationId } from 'api/Location/model/LocationId';
import { IUserName } from 'api/UserAccount/model/IUserName';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';


export class TermsConditionsAgreement {
    constructor (
        private readonly locationId : LocationId,
        private readonly userId : UserAccountId,
        private readonly timeCreated : moment.Moment,
        private readonly userName : IUserName,
    ) { }

    public getLocationId() : LocationId {
        return this.locationId;
    }

    public getUserId() : UserAccountId {
        return this.userId;
    }

    public getTimeCreated() : moment.Moment {
        return this.timeCreated;
    }

    public getUserName() : IUserName {
        return this.userName;
    }

    public getAgreementDisplayString() : string {
        return `${this.userName.firstName} ${this.userName.lastName} accepted BevSpot's terms and conditions on ${this.timeCreated.format('LL')}.`;
    }
}
